import { Autocomplete, Chip, Divider, FormHelperText, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ColorPicker } from 'material-ui-color';
import { Fragment, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import Editor from 'src/components/editor';
import { RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import { CustomFile } from "src/components/upload";
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { fData } from 'src/utils/format-number';

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    formDetails: any;
    currentData:any;
    indexValue:any;
    setNewModelSchema:(data:any)=>void;
    setValue:any;
    getValues:any;
    control:any;
    subFormName?:any;
    inputTypeSize?:any
};

export default function DynamicFormTemplate({ formDetails,setValue,getValues,control,setNewModelSchema,currentData,indexValue,subFormName = "",inputTypeSize }: Props) {
    const { t } = useLocales();
    const handleDropSingleFile = useCallback((acceptedFiles: File[], column: any) => {
        const file = acceptedFiles[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            console.log(fileReader.result);
            setValue(column, fileReader.result, { shouldValidate: true });
        }
        fileReader.onerror = (error) => {
            console.log(error);
        }
    }, [setValue])

    // const dropDownDataFilter = useCallback((controlObj: any, parentName: string, childReference: string) => {
    //     let data: any = [];
    //     if (parentName) {
    //         data = controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10))
    //     } else if (childReference) {
    //         data = controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.childReference], 10))
    //     }
    //     return data;
    // }, [])
    const renderForm = useCallback((formDetail: any, index: any) => {
        const newRecords = formDetail && formDetail.map((controlObj: any, controlIndex: any) => {
            const randomUUID:any  = crypto.randomUUID();
            switch (controlObj.inputType) {
                case "TEXTAREA":
                    return    <Controller key={`${randomUUID}1`}
                    name={`${subFormName}${controlObj.columnName}`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Editor key={`${randomUUID}2`}
                        id={`${subFormName}${controlObj.columnName}`}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!error}
                        helperText={
                          (!!error ) && (
                            <FormHelperText key={`${randomUUID}3`} error={!!error} sx={{ px: 2 }}>
                              {error ? error?.message : "helperText"}
                            </FormHelperText>
                          )
                        }
                      />
                    )}
                  />
                    // <RHFEditor  key={Math.random()} simple sx={{ height: 200 }} name={`${subFormName}${controlObj.columnName}`} onChange={(value:any) => console.log(value)} />
                case "DATETIME":
                    return <Controller key={`${randomUUID}4`}
                        name={`${subFormName}${controlObj.columnName}`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <DatePicker key={`${randomUUID}5`}
                                label={controlObj.label}
                                value={field.value}
                                onChange={(newValue: any) => {
                                    field.onChange(newValue);
                                }}
                                format="dd-MMM-yyyy"
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: !!error,
                                        helperText: error?.message,
                                    },
                                }}
                            />
                        )}
                    />
                case "PASSWORD":
                    return <RHFTextField autoComplete='off' type='password' id={`${subFormName}${controlObj.columnName}`} key={`${randomUUID}6`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                case "NUMBER":
                    return <RHFTextField autoComplete='off' type='number'  id={controlObj.columnName}  key={`${randomUUID}7`} size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                case "EMAILADDRESS":
                    return <RHFTextField type='email' autoComplete='off' key={`${randomUUID}8`}  id={`${subFormName}${controlObj.columnName}`}  size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                case "TEXTBOX":
                    return <RHFTextField autoComplete='off' key={`${randomUUID}8`}  id={`${subFormName}${controlObj.columnName}`}  size={inputTypeSize} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                case "DROPDOWN": {
                    let optionData:any = !controlObj.dependencyDropdownKeyCol ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
                    optionData =  typeof optionData ==='string'?[]:optionData
                    return <RHFSelect key={`${randomUUID}9`}  id={`${subFormName}${controlObj.columnName}`}  name={`${subFormName}${controlObj.columnName}`}
                        label={t(controlObj.label)} size={inputTypeSize}
                        onChange={(e) => {
                            const filterData = optionData?.filter((x:any)=>x.Id===e.target.value)[0]
                            setValue(`${subFormName}${controlObj.columnName}1`, filterData[controlObj.dropdownLabelName]);
                            setValue(`${subFormName}${controlObj.columnName}`, e.target.value);
                            if (controlObj.childReference) {
                                controlObj.childReference.split(',').forEach((item: any) => {
                                    const dataD = (controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)).filter((x: any) => x.Id === e.target.value)[0][item];
                                    setValue(controlObj.refDependencyDropdownKeyCol, dataD)
                                })
                            }
                        }}
                    >
                        <MenuItem key={`${randomUUID}10`} value=''>None</MenuItem>
                        <Divider key={`${randomUUID}11`} sx={{ borderStyle: 'dashed' }} />
                        {optionData?.map((item: any) => (
                            <MenuItem key={`${randomUUID}${item.columnName}${item.Id}`} value={item.Id}> {item[controlObj.dropdownLabelName]} </MenuItem>
                        ))}
                    </RHFSelect>
                }
                case "MULTISELECT": { 
                    let optionData:any = !controlObj.dependencyDropdownKeyCol ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
                    optionData =  typeof optionData ==='string'?[]:optionData
                    let defaultControlValuesList:any = [];
                    defaultControlValuesList = optionData.filter((x:any)=> getValues()[`${subFormName}${controlObj.columnName}`]?.split(',').includes(x.Id.toString()))
                    return <>
                
             <Autocomplete key={`${randomUUID}12`} 
             id={`${subFormName}${controlObj.columnName}`} 
                            fullWidth
                            multiple
                            size={inputTypeSize}
                            options={optionData}
                            defaultValue={defaultControlValuesList}
                            // isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                            getOptionLabel={(option: any) => option[controlObj.dropdownLabelName]}
                            onChange={(event, newValue) => {
                                let ids = "";
                                newValue?.forEach((item) => {
                                    ids += `${item.Id},`;
                                });
                                // setValue("controlIds", ids);
                                const filterData = optionData?.filter((x:any)=>ids?.split(',').includes(x.Id.toString()))
                                let cancatinate:any = "";
                                filterData.forEach((element:any) => {
                                    if(filterData.length===1){
                                        cancatinate +=`${element[controlObj.dropdownLabelName]}`;
                                    } else {
                                        cancatinate +=`${element[controlObj.dropdownLabelName]},`;
                                    }
                                });
                                setValue(`${subFormName}${controlObj.columnName}1`, cancatinate);
                                setValue(`${subFormName}${controlObj.columnName}`,ids);
                            }}
                            renderInput={(params) => (
                                <TextField  key={`${randomUUID}13`}  {...params} label={t(controlObj.label)} placeholder={controlObj.label} />
                            )}
                            renderOption={(props, option: any) => (
                                <li key={`${randomUUID}14`} {...props} >
                                    {option[controlObj.dropdownLabelName]}
                                </li>
                            )}
                            renderTags={(selected, getTagProps) =>
                                selected.map((option: any, indexD) => (
                                    <Chip 
                                        {...getTagProps({ index:indexD })}
                                        key={`1${randomUUID}${indexD}`}
                                        label={option[controlObj.dropdownLabelName]}
                                        size={GLOBALCLASSINPUT}
                                        color="info"
                                        variant="soft"
                                    />
                                ))
                            }
                        />
            </> 
                    
                }
                case "COLORPICKER": {
                    return <Stack spacing={1} sx={{ p: 1 }} key={Date.now().toString(36)} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(2, 1fr)` }}> {controlObj.label ? controlObj.label : 'Pick Color'}:
                        <ColorPicker disableAlpha key={`dynamic${controlObj.columnName}`} defaultValue={currentData[`${subFormName}${controlObj.columnName}`]} value={getValues()[`${subFormName}${controlObj.columnName}`]}
                         onChange={(data: any) => {
                             setValue(`${subFormName}${controlObj.columnName}`, data.css.backgroundColor);
                             }} />
                             </Stack>
                }
                case "FILEUPLOAD": {
                    return <RHFUploadAvatar minSize={20}   key={`${subFormName}${controlObj.columnName}`}
                        helperText={
                            <Typography
                                variant="caption"
                                sx={{
                                    mt: 3,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.disabled',
                                }}
                            >
                                Allowed *.jpeg, *.jpg, *.png, *.gif
                                <br /> max size of {fData(3145728)}
                            </Typography>
                        }
                        name={`${subFormName}${controlObj.columnName}`}
                        onDrop={(e: any) => handleDropSingleFile(e, `${subFormName}${controlObj.columnName}`)}
                        onDelete={() => setValue(`${subFormName}${controlObj.columnName}`, '', { shouldValidate: true })}
                    />
                }
                case "CHECKBOX": {
                    return <RHFSwitch id={`${subFormName}${controlObj.columnName}`}  key={Math.random()} defaultValue={currentData[`${subFormName}${controlObj.columnName}`] ? currentData[`${subFormName}${controlObj.columnName}`] : false} name={`${subFormName}${controlObj.columnName}`} label={t(controlObj.label)} />
                }
                default:
                    return <></>;
            }
        })

        return newRecords;
    }, [])

    return (
        <Fragment key={Math.random()}>
            {renderForm(formDetails,indexValue)}
        </Fragment>
    )
}