import { Divider, Typography } from "@mui/material";
import { Fragment } from "react";
import { CustomFile } from "src/components/upload";
import MultiRecordDynamicFormTemplate from "../multi-record-dynamic-form-template";


interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    index: any;
    item: any;
    currentModel: any;
    setValue: any;
    getValues: any;
    newModelSchemaSubForm: any;
    setNewModelSchemaSubForm: any;
    watchField: any;
};

export default function MultipleDynamicForm({ index, item, currentModel, setNewModelSchemaSubForm, newModelSchemaSubForm, setValue, getValues, watchField }: Props) {
   const randomUUID:any  = crypto.randomUUID();
   return <Fragment key={`${randomUUID}1`} >
        {(item?.blockName) !== 'Default' && <Fragment key={`${randomUUID}2`} >
            <Typography variant="subtitle1" key={`${randomUUID}3`} style={{ width: "100%", paddingTop: "10px" }}> {item.blockName} </Typography>
            <Divider key={`${randomUUID}4`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
        </Fragment>}
        {(item?.blockName) === 'Default' &&
            <Divider key={`${randomUUID}5`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
        }
        <MultiRecordDynamicFormTemplate subFormName={item.subFormName ? `${item.subFormName}` : ""} key={`${randomUUID}6`} watchField={[...new Set([...watchField])]}
            id={item.subFormId} setParentValues={setValue} getParentValues={getValues}
            formDetails={item.subFormDetail} currentData={currentModel}
            indexValue={index} setNewModelSchema={setNewModelSchemaSubForm} newModelSchema={newModelSchemaSubForm} />
    </Fragment>;
}