import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import { CustomFile } from "src/components/upload";
import DynamicFormTemplate from "../dynamic-form-template";


interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    index: any;
    item: any;
    currentModel: any;
    setValue: any;
    getValues: any;
    control: any;
    setNewModelSchema:any;
    handleChangeControlled:any;
};

export default function CollapsibleDynamicForm({index, item, currentModel, setNewModelSchema, setValue, getValues, control,handleChangeControlled}: Props) {
    const randomUUID:any  = crypto.randomUUID();
    return <Accordion key={`${randomUUID}1`} defaultExpanded
    onChange={handleChangeControlled(`panel${item.id + 1}`)}>
    <AccordionSummary expandIcon={<Iconify key={`${randomUUID}3`}  icon="eva:arrow-ios-downward-fill" />} >
        <Typography key={`${randomUUID}2`}  variant="subtitle1" style={{ width: "100%" }}>{item.blockName}   </Typography>
    </AccordionSummary>
    <AccordionDetails key={`${randomUUID}4`} >
        <Stack spacing={2} display="grid" key={`${randomUUID}5`}  gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${item.flex}, 1fr)` }}>
            <DynamicFormTemplate key={`${randomUUID}6`}  subFormName={item.subFormName ? `${item.subFormName}.` : ""} formDetails={item.formDetail} currentData={currentModel}
                indexValue={index} setNewModelSchema={setNewModelSchema}
                setValue={setValue} getValues={getValues} control={control} />
        </Stack>
    </AccordionDetails>
</Accordion>

}