
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useLocales } from 'src/locales';
import { getViewDataByTId } from 'src/redux/slices/settings/table-configuration';
import * as Yup from 'yup';
import DynamicFormTemplate from './dynamic-form-template';
import CollapsibleDynamicForm from './render-blocks/collapsible-template';
import DefaultDynamicForm from './render-blocks/default-template';

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    formDetails: any;
    currentData: any;
    indexValue: any;
    setNewModelSchema: (data: any) => void;
    id: any;
    watchField: any[];
    setParentValues?: any;
    getParentValues?: any;
    newModelSchema: any
    subFormName: any;
};

export default function MultiRecordDynamicFormTemplate({ subFormName, formDetails, setNewModelSchema, setParentValues, getParentValues, newModelSchema, currentData, id,
    indexValue, watchField }: Props) {
    const { t } = useLocales();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [loading, setLoading] = useState(false as any);
    const [showError, setShowError] = useState(false as any);
    const [formBlockList, setFormBlockList] = useState([...currentData.formBlockList.filter((x: any) => !x.isTab)] as any[]);
    const [tabFormBlockList, setTabFormBlockList] = useState([...currentData.formBlockList.filter((x: any) => x.isTab)] as any[]);
    const [collapseFormBlockList, setCollapseFormBlockList] = useState([...currentData.formBlockList.filter((x: any) => x.isTab)] as any[]);
    const [newModelSchemas, setNewModelSchemas] = useState({} as any);
    const [watchFields, setWatchFields] = useState([] as any);
    const [currentTab, setCurrentTab] = useState(tabFormBlockList[0]?.id);
    const [controlled, setControlled] = useState<string | false>(formBlockList.filter((x: any) => x.blockType === "COLLAPSIBLE" || x.blockType === "MULTIPLECOLLAPAPSIBLE")[0]?.id);
    const [columns, setColumns] = useState([] as any);
    const [tableHeadColumn, setTableHeadColumn] = useState([] as any);
    const [tableData, setTableData] = useState([] as any);
    const [totalRowsCount, setTotalRowsCount] = useState(0 as any);
    const [formFields, setFormFields] = useState({} as any);
    useEffect(() => {
        // setValue(`${subFormName}.ID`, "0")
        getViewDataByIdBind();
        const shapeDetail: any = {} as any;
        formDetails?.forEach((item: any) => {
            item.formDetail.forEach((item1: any) => {
                if (item.subFormId > 0) {
                    watchFields.push(item1.columnName);
                    if (item1.dependencyDropdownKeyCol) {
                        watchFields.push(item1.dependencyDropdownKeyCol);
                    }
                    if (item1.refDependencyDropdownKeyCol) {
                        watchFields.push(item1.refDependencyDropdownKeyCol);
                    }
                    if (item1.childReference) {
                        watchFields.push(item1.childReference);
                    }
                } else {
                    if (item1.dependencyDropdownKeyCol) {
                        watchFields.push(item1.dependencyDropdownKeyCol);
                        watchFields.push(item1.columnName);
                    }
                    if (item1.refDependencyDropdownKeyCol) {
                        watchFields.push(item1.columnName);
                    }
                }
                bindData(item1, {}, shapeDetail);
            });
        })
        // watchFields.push(subFormName);
        setWatchFields([...new Set([...watchFields])])
        setFormFields({ ...formFields })
        setNewModelSchemas({ ...shapeDetail });
    }, [id, setTableData])

    const bindData = useCallback((controlObj: any, shape: any, shapeDetail: any) => {
        switch (controlObj.dataType) {
            case "BIT": {
                formFields[controlObj.columnName] = false;
                shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(`${controlObj.label} is required`) : Yup.string();
                break;
            }
            case "DATETIME": {
                formFields[controlObj.columnName] = new Date();
                shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(`${controlObj.label} is required`) : Yup.string();
                break;
            }
            case "NVARCHAR(MAX)": {
                formFields[controlObj.columnName] = "";
                shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(`${controlObj.label} is required`) : Yup.string();
                break;
            }
            case "INT": {
                if (controlObj.inputType === "DROPDOWN") {
                    formFields[controlObj.columnName] = "";
                    shapeDetail[controlObj.columnName] = controlObj.required ? Yup.string().required(`${controlObj.label} is required`) : Yup.string();
                } else {
                    formFields[controlObj.columnName] = "0";
                    shapeDetail[controlObj.columnName] = controlObj.required ? Yup.number().required(`${controlObj.label} is required`) : Yup.number();
                }
                break;
            }
            default:
                break;
        }
    }, [])

    const defaultValue = useMemo(() => ({
        ...formFields
    }), [formFields]);

    const methodss = useForm<any>({
        resolver: yupResolver(Yup.object().shape(newModelSchemas)),
        defaultValues: { ...defaultValue }
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting }, watch, control, getValues, setValue
    } = methodss;

    const values = watch(watchFields);

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            data.ID = (data.Id || data.ID) || 0;
            console.log(data);
            if (data.ID > 0) {
                const parentData = getParentValues()[subFormName]?.filter((x: any) => x.ID === data.ID)[0];
                const gridData = getValues()[subFormName]?.filter((x: any) => x.Id === data.ID)[0];
                Object.keys(data).forEach((key: any) => {
                    if (data[`${key}1`] && key !== 'Id' && key !== 'ID') {
                        parentData[key] = data[key];
                        gridData[key] = data[`${key}1`];
                    }
                })
                setValue(subFormName, getValues()[subFormName])
            } else {
                data.Id = 0;
                if (data.index) {
                    const parentData = getParentValues()[subFormName][data.index];
                    const gridData = getValues()[subFormName][data.index];
                    Object.keys(data).forEach((key: any) => {
                        if (data[`${key}1`] && key !== 'Id' && key !== 'ID') {
                            parentData[key] = data[key];
                            gridData[key] = data[`${key}1`];
                        }
                    });
                    setValue(subFormName, getValues()[subFormName]);
                } else {
                    const subFormData = typeof getParentValues()[subFormName] === 'string' ? JSON.parse(getParentValues()[subFormName]) : getParentValues()[subFormName];
                    const gridData = {} as any;
                    Object.keys(data).forEach((key: any) => {
                        if (!data[`${key}1`] && !data[`${key}`] && key !== 'Id' && key !== 'ID') {
                            gridData[key] = data[`${key}`];
                        }
                        if (data[`${key}1`] && key !== 'Id' && key !== 'ID') {
                            gridData[key] = data[`${key}1`];
                        }
                    });
                    const formData = getValues()[subFormName] === "0" ? [] : getValues()[subFormName];
                    if (formData) {
                        formData.push({ ...gridData });
                        setValue(subFormName, formData);
                    }
                    subFormData.push({ ...data })
                    setParentValues(subFormName, subFormData)
                }
            }
            Object.keys(getParentValues()[`${subFormName}FORM`])?.forEach((item: any) => {
                setValue(item, getParentValues()[`${subFormName}FORM`][item]);
            })
            setTableData([...getValues()[subFormName]]);
            setValue('Id', undefined);
            setValue('ID', undefined);
            setValue('index', undefined)
        }, [])

    const getViewDataByIdBind = useCallback((pageNumber: number = 0, rowsPerPage: number = 100, searchText: any = "") => {
        setLoading(true);
        getViewDataByTId({ pageId: id, pageNumber, rowsPerPage, searchText }).then((res: any) => {
            if (res.data && res.data) {
                const data = JSON.parse(res.data.data);
                console.log(data);
                const colsDat: any = data.Table.sort((a: any, b: any) => (a.DisplayOrder < b.DisplayOrder ? -1 : 1));
                const colStringArra: any = [];
                colsDat.forEach((item: any) => {
                    colStringArra.push(item.id);
                })
                setColumns([...colsDat]);
                setTableHeadColumn([...colsDat, { id: 'id', width: 88, label: 'Action' }]);
                const gridData = data.Table2 ? data.Table2 : [];
                setTableData([...gridData]);
                setValue(subFormName, gridData);
                setParentValues(subFormName, currentData[subFormName] ? JSON.parse(currentData[subFormName]) : []);
                const cols = data.Table1[0]?.Columns?.split(',') || [];
                setLoading(false);
            }
        });
    }, [id]);

    const handleChangeControlled =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setControlled(isExpanded ? panel : false);
        };

    const handleOnEditClick = useCallback((row: any, index: any) => {
        let data: any = {};
        if (row.Id > 0) {
            data = getParentValues()[subFormName]?.filter((x: any) => x.ID === row.Id)[0];
        } else {
            data = getParentValues()[subFormName][index]
        }
        Object.keys(data).forEach((item) => {
            if (item !== subFormName)
                setValue(item, data[item]);
        })
        setValue('ID', (row.Id || data.ID) || 0)
        setValue('Id', (row.Id || data.ID) || 0)
        setValue('index', index)
    }, [])

    const handleOnDeleteClick = useCallback((row: any, index: any) => {
        if (row.Id > 0) {
            const data = getParentValues()[subFormName]?.filter((x: any) => x.ID !== row.Id);
            const gridData = getValues()[subFormName]?.filter((x: any) => x.Id !== row.Id);
            setValue(subFormName, [...gridData])
            setParentValues(subFormName, data)
        } else {
            delete getParentValues()[subFormName][index];
            delete getValues()[subFormName][index];
            setValue(subFormName, [...getValues()[subFormName]])
            setParentValues(subFormName, getParentValues()[subFormName])
        }
    }, [])

    const renderBlock = useCallback((item: any, index: any) => {
        const subFormNameForm = "";
        switch (item.blockType) {
            case "DEFAULT" && item.blockName !== "Default":
                return <Fragment key={Math.random()}> <Fragment key={Math.random()} >
                    <Typography variant="subtitle1" key={Math.random()} style={{ width: "100%", paddingTop: "10px" }}> {item.blockName} </Typography>
                    <Divider key={Math.random()} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
                </Fragment> <Fragment key={Math.random()}>
                        <Stack spacing={2} display="grid" key={Math.random()} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${item.flex}, 1fr)` }}>
                            <Fragment key={Math.random()}>
                                <DynamicFormTemplate subFormName={subFormNameForm} key={Math.random()} formDetails={item.formDetail} currentData={currentData}
                                    indexValue={index} setNewModelSchema={setNewModelSchema} setValue={setValue} getValues={getValues} control={control} />
                            </Fragment>
                        </Stack>
                    </Fragment></Fragment>
            case "DEFAULT": {
                    const data ={index, item, currentData, setNewModelSchema, setValue, getValues, control} as any
                    return <DefaultDynamicForm key={Math.random()} {...data} /> 
                }
            case "COLLAPSIBLE": {
                    const data ={index, item, currentData, setNewModelSchema, setValue, getValues, control,handleChangeControlled} as any
                    return <CollapsibleDynamicForm key={`${crypto.randomUUID()}`} {...data} /> 
                }
            case "MULTIPLECOLLAPAPSIBLE":
                return <Fragment key={Math.random()}>
                    <Stack spacing={2} display="grid" key={Math.random()} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${item.flex}, 1fr)` }}>
                        <Fragment key={Math.random()}>
                            <DynamicFormTemplate subFormName={subFormNameForm} key={Math.random()} formDetails={item.formDetail} currentData={currentData}
                                indexValue={index} setNewModelSchema={setNewModelSchema} setValue={setValue} getValues={getValues} control={control} />
                        </Fragment>
                    </Stack>
                </Fragment>
            default:
                return ""
        }
    }, []);

    const Greet = () => 
         (
            <Table sx={{ minWidth: "100%" }}>
            <TableHead>
                <TableRow>
                    {columns && columns?.map((item: any) => (
                        <TableCell key={item.label} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                            <Typography variant="caption" sx={{ mb: 0.5 }}>  {t(item.label)} </Typography>
                        </TableCell>
                    ))}
                    <TableCell style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                        <Typography variant="caption" sx={{ mb: 0.5 }}> &nbsp; </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {getValues()[subFormName] && getValues()[subFormName]?.map((row: any, index: any) => (
                    <TableRow key={`key${index}`}>
                        {columns && columns.map((item: any) => (
                            <TableCell key={item.id} style={{ padding: "4px", fontSize: '10px', textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ mb: 0.5 }}>  {row[item.id]} </Typography>
                            </TableCell>
                        ))}
                        <TableCell key={`action${index}`} align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                            <Tooltip key={`tooltip${index}`} title="Quick Edit" placement="top" arrow>
                                <IconButton key={`icon${index}`} onClick={() => {
                                    handleOnEditClick(row, index);
                                }}>
                                    <Iconify icon="solar:pen-bold" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip key={`tooltipDelete${index}`} title="Quick Delete" sx={{ color: 'error.main' }} placement="top" arrow>
                                <IconButton key={`iconDelete${index}`} onClick={() => { handleOnDeleteClick(row, index) }}  >
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}

            </TableBody>
        </Table>
        )

    return (

        <Fragment key={Math.random()}>
            <FormProvider methods={methodss}>
                <Fragment key={Math.random()}>
                    {formDetails.filter((x: any) => !x.isTab).map((item: any, index: any) => (
                        <Fragment key={Math.random()}>
                            {renderBlock(item, index)}
                        </Fragment>
                    ))}
                    <br />
                    <Fragment key={Math.random()}>
                        <Stack spacing={2} display="grid" key={Math.random()} style={{ textAlign: "center" }} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(4, 1fr)` }}>
                            <Button variant="outlined" onClick={() => {
                                Object.keys(getParentValues(`${subFormName}FORM`)).forEach((item: any) => {
                                    setValue(item, getParentValues(`${subFormName}FORM`)[item]);
                                })
                            }} >
                                Clear
                            </Button>
                            <Button variant="outlined" onClick={handleSubmit(onSubmit)}>
                                Add
                            </Button>
                        </Stack>
                    </Fragment>
                </Fragment>
            </FormProvider>
            <br />
            <Greet/>
        </Fragment>
    )
}