import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomFile } from "src/components/upload";
import { newFormGetFormBlockSetupTableId } from 'src/utils/dynamic.form.generation.common.code';
import DynamicFormFormTemplateById from './dynamic-form-form-template-by-Id';

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    title: string;
    open: boolean;
    onClose: VoidFunction;
    submitButtonText?: string;
    currentModel: any;
    children:any;
    onSubmitHandler:(res:any)=>VoidFunction;
};

export default function DynamicFormNewPageById({pageId,inputTypeSize,children,onSubmitHandler}:any) {
    const [selectedRow, setSelectedRow] = useState({} as any);
    const [newModelSchema, setNewModelSchema] = useState({} as any);
    const [watchFields, setWatchFields] = useState([] as any);
    const id = 0;

    useEffect(() => {
      newFormGetFormBlockSetupTableId(id,pageId,watchFields,setWatchFields,setSelectedRow);
    }, [])

    const defaultValues = useMemo(() => ({
        ...selectedRow
    }), [selectedRow]);

    const methods = useForm<any>({
        resolver: yupResolver(newModelSchema),
        defaultValues
    });

    return (
       <>
        {selectedRow && selectedRow.formBlockList && <DynamicFormFormTemplateById onSubmitHandler={onSubmitHandler} pageId={pageId} key='DynamicForm' inputTypeSize={inputTypeSize} currentModel={selectedRow} children={children}/>}
       </>
    )
}