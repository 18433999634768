import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Stack, Tab, Tabs } from '@mui/material';
import Alert from '@mui/material/Alert';
import { alpha, useTheme } from '@mui/material/styles';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import FormProvider from 'src/components/hook-form';
import { useSnackbar } from "src/components/snackbar";
import { CustomFile } from "src/components/upload";
import { useLocales } from 'src/locales';
import { insertUpdateFormDetail } from 'src/redux/slices/settings/table-configuration';
import { useRouter } from 'src/routes/hook';
import { bindDataModel, formBlockListData } from 'src/utils/dynamic.form.generation.common.code';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import CollapsibleDynamicForm from './render-blocks/collapsible-template';
import DefaultDynamicForm from './render-blocks/default-template';
import MultipleDynamicForm from './render-blocks/multiple-block-template';
import MultipleCollapsibleDynamicForm from './render-blocks/multiple-collapsible-template';

interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    currentModel: any;
    inputTypeSize:any;
    children:any;
    pageId:any;
    onSubmitHandler:(res:any)=>VoidFunction;
};

export default function DynamicFormFormTemplateById({pageId, currentModel,inputTypeSize,children,onSubmitHandler }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const { t } = useLocales();
    const [showError, setShowError] = useState(false as any);
    const [formBlockList, setFormBlockList] = useState([...currentModel.formBlockList.filter((x: any) => !x.isTab)] as any[]);
    const [tabFormBlockList, setTabFormBlockList] = useState([...currentModel.formBlockList.filter((x: any) => x.isTab)] as any[]);
    const [collapseFormBlockList, setCollapseFormBlockList] = useState([...currentModel.formBlockList.filter((x: any) => x.isTab)] as any[]);
    const [newModelSchema, setNewModelSchema] = useState({} as any);
    const [newModelSchemaSubForm, setNewModelSchemaSubForm] = useState({} as any);
    const [watchFields, setWatchFields] = useState([...currentModel.watchFields] as any);
    const [currentTab, setCurrentTab] = useState(tabFormBlockList[0]?.id);
    const [controlled, setControlled] = useState<any>(formBlockList.filter((x: any) => x.blockType === "COLLAPSIBLE" || x.blockType === "MULTIPLECOLLAPAPSIBLE")[0]?.id);
    const router = useRouter();
    const param = useParams();
    const id = param?.id||0;

    const defaultValues = useMemo(() => ({
        ...currentModel
    }), [currentModel]);

    const methods = useForm<any>({
        resolver: yupResolver(newModelSchema),
        defaultValues
    });

    const {
        handleSubmit, setValue, getValues,watch, control,
        formState: { isSubmitting }
    } = methods;

    let values:any;
    if(watchFields?.length>0)
    values = watch(watchFields);

    const onSubmit = useCallback(
        async (data: FormValuesProps) => {
            const obj: any = JSON.parse(JSON.stringify(data));
            delete obj.ddlData;
            delete obj.formBlockList;
            delete obj.watchFields;
            console.log(obj);
            insertUpdateFormDetail({ pageId, id: currentModel.Id, jsonData: JSON.stringify(obj) }).then((res: any) => {
                if (res.data.data > 0 && res.data.statusCode === 200) {
                    onSubmitHandler(res.data.data) ;
                } else {
                    setShowError(true);
                }
            })
        }, [id, currentModel, enqueueSnackbar, t]);

    const bindData = useCallback((controlObj: any, shape: any, shapeDetail: any, subFormName: any) => {
        bindDataModel(controlObj, shape, shapeDetail, subFormName,currentModel,setValue);
    }, [])

    useEffect(() => {
        console.log(router)
        const shape: any = {} as any;
        const shapeSubForm: any = {} as any;
        formBlockListData(shape,shapeSubForm,formBlockList,currentModel,setValue,tabFormBlockList,setNewModelSchema,setNewModelSchemaSubForm);
    }, [])

    const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    }, []);

    const handleChangeControlled =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setControlled(isExpanded ? panel : false);
        };

    const renderBlock = useCallback((itemData: any, index: any) => {
        const item: any = JSON.parse(JSON.stringify(itemData));
        item.formDetail = item?.formDetail?.filter((x: any) => x.inputType !== 'FILEUPLOAD')
        switch (item.blockType) {
            case "DEFAULT": {
                const data ={index, item, currentModel, setNewModelSchema, setValue, getValues, control} as any
                return <DefaultDynamicForm key={`${crypto.randomUUID()}`} {...data} inputTypeSize={inputTypeSize}/> 
            }
            case "COLLAPSIBLE": {
                    const data ={index, item, currentModel, setNewModelSchema, setValue, getValues, control,handleChangeControlled} as any
                    return <CollapsibleDynamicForm key={`${crypto.randomUUID()}`} {...data} inputTypeSize={inputTypeSize}/> 
                }
            case "MULTIPLE":  {
                const watchField: any = [];
                    const data ={index, item, currentModel, setNewModelSchemaSubForm,newModelSchemaSubForm, setValue, getValues,watchField} as any
                    return <MultipleDynamicForm key={`${crypto.randomUUID()}`} {...data} inputTypeSize={inputTypeSize}/> 
            }
            case "MULTIPLECOLLAPAPSIBLE": {
                const watchField: any = [];
                    const data ={index, item, currentModel, setNewModelSchemaSubForm,newModelSchemaSubForm, setValue, getValues,watchField,handleChangeControlled} as any
                    return <MultipleCollapsibleDynamicForm key={`${crypto.randomUUID()}`} {...data} inputTypeSize={inputTypeSize}/> 
            }
            default:
                return ""
        }
    }, []);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {showError && <Alert key={Math.random()} variant="outlined" severity="error" >
                Already exists with this record. Please try again
            </Alert>}
            <Stack spacing={1} sx={{ p: 1 }} key={Math.random()} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
                {formBlockList.filter(x => !x.isTab).map((item: any, index: any) => (
                    <Fragment key={Math.random()}>
                        {renderBlock(item, index)}
                    </Fragment>
                ))}
                {tabFormBlockList.length > 0 && <Card sx={{ padding: "0px 10px 15px 10px" }}>
                    <Tabs value={currentTab} sx={{
                        px: 2.5,
                        boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                    }} onChange={handleChangeTab}>
                        {tabFormBlockList.filter(x => x.isTab).map((item: any, index: any) => (
                            <Tab key={Math.random()} value={item.id} label={item?.tabTitle || item?.blockName} />
                        ))}
                    </Tabs>
                    {tabFormBlockList.filter(x => x.isTab).map((tab, index) =>
                        tab.id === currentTab && (
                            <Fragment key={Math.random()}>
                                {renderBlock(tab, index)}
                            </Fragment>
                        )
                    )}
                </Card>
                }
            </Stack>
            {children}
        </FormProvider>
    )
}