import { Helmet } from 'react-helmet-async';
// sections
import DealerClassicLayout from 'src/layouts/auth/dealer';
import { JwtLoginView } from 'src/sections/auth/jwt';
import { DealerLoginView } from 'src/sections/dealer';

// ----------------------------------------------------------------------

export default function DealerLoginPage() {
    return (
        <>
            <DealerClassicLayout>
                <>
                    <Helmet>
                        <title> DAS360: Login</title>
                    </Helmet>
                    <JwtLoginView />
                </>
            </DealerClassicLayout>
        </>
    );
}
