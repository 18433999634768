import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { RouterLink } from 'src/routes/components';
import { useSearchParams } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// config
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import DynamicFormNewPageById from 'src/sections/masters/settings/dynamic-form/dynamic-form-new-page-by-id';
import { CONSTANTDEFAULTPAGES } from 'src/utils/contants/config-utils';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function JwtRegisterView() {
  const { register } = useAuthContext();
  const { t } = useLocales();
  const [errorMsg, setErrorMsg] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState(false);

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');


  const methods = useForm<FormValuesProps>({
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmitHandler = useCallback(
    (data: any) => {
      alert(data);
      if (data > 0) {
        setRegistrationStatus(true);
      }
    }, []);


  const renderHead = (
    <Stack spacing={2} sx={{ mb: 2, position: 'relative' }}>
      <Typography variant="h4">{t("LOGINFORMTITLE")}</Typography>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> {t('AREADY_HAVE_ACCOUNT')} </Typography>
        <Link href={paths.auth.jwt.login} component={RouterLink} variant="subtitle2">
        {t('SIGNIN')}
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      {t('SIGNUP_IAGREE')}
      <Link underline="always" color="text.primary">
        {t('TERMS_SERVICE')}
      </Link>
       &nbsp;{t('AND')} &nbsp;
      <Link underline="always" color="text.primary">
        {t('PRIVACY_POLICY')}
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      {!registrationStatus && <DynamicFormNewPageById inputTypeSize="medium" pageId={CONSTANTDEFAULTPAGES.REGISTRATIONPAGE} onSubmitHandler={onSubmitHandler} 
      children={<LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
       {t("CREATE_ACCOUNT")}
      </LoadingButton>} />}

     {registrationStatus &&   <>
       <Alert severity="success">{t("REGISTRATION_SUCCESS")}</Alert>
       <Alert severity="success"><div dangerouslySetInnerHTML= {{__html: t("REGISTRATION_SUCCESS_CONTENT") as any}}/></Alert>
      </> }
    </Stack>
  );

  return (
    <>
      {renderHead}

      {renderForm}

      {renderTerms}
    </>
  );
}
