// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
//
// import Footer from './footer';
// import Header from './header';
import LoginPage from 'src/pages/auth/jwt/login';
import DealerLoginPage from 'src/pages_new/dealer-channel/login';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  const pathname = usePathname();

  const isHome = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      {/* <Header />
      <div style={{height : '100%'}} />
      <Footer /> */}
      <DealerLoginPage/>
    </Box>
  );
}
