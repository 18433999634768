import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import Iconify from "src/components/iconify";
import { CustomFile } from "src/components/upload";
import MultiRecordDynamicFormTemplate from "../multi-record-dynamic-form-template";


interface FormValuesProps extends Omit<any, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type Props = {
    index: any;
    item: any;
    currentModel: any;
    setValue: any;
    getValues: any;
    newModelSchemaSubForm: any;
    setNewModelSchemaSubForm: any;
    handleChangeControlled: any;
    watchField: any;
};

export default function MultipleCollapsibleDynamicForm({ index, item, currentModel, setNewModelSchemaSubForm, newModelSchemaSubForm, setValue, getValues, watchField, handleChangeControlled }: Props) {
    return <Accordion key={item.id} defaultExpanded
        onChange={handleChangeControlled(item.id)}>
        <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />} >
            <Typography variant="subtitle1" style={{ width: "100%" }}>{item.blockName}   </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <MultiRecordDynamicFormTemplate subFormName={item.subFormName ? `${item.subFormName}` : ""} key={Math.random()} watchField={[...new Set([...watchField])]}
                id={item.subFormId} setParentValues={setValue} getParentValues={getValues}
                formDetails={item.subFormDetail} currentData={currentModel}
                indexValue={index} setNewModelSchema={setNewModelSchemaSubForm} newModelSchema={newModelSchemaSubForm} />
        </AccordionDetails>
    </Accordion>;
}