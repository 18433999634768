import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
export const LandingPage = lazy(() => import('src/pages_new/login'));
export const AdminLoginPage = lazy(() => import('src/pages_new/admin-channel/login'));
export const DealerLoginPage = lazy(() => import('src/pages_new/dealer-channel/login'));
export const DealerLoginPhonePage = lazy(() => import('src/pages_new/dealer-channel/login-phone'));
export const DealerRegisterPage = lazy(() => import('src/pages_new/dealer-channel/register'));
export const DealerForgotPasswordPage = lazy(() => import('src/pages_new/dealer-channel/forgot-password'));
export const DealerVerifyRecoverPasswordPage = lazy(() => import('src/pages_new/dealer-channel/verify-recover-password'));
export const DealerNewPasswordPage = lazy(() => import('src/pages_new/dealer-channel/new-password'));
export const DealerLoginVerifyPage = lazy(() => import('src/pages_new/dealer-channel/verify-login'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'faqs', element: <FaqsPage /> },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Outlet />
      </SimpleLayout>
    ),
    children: [
    ],
  },
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
  {
    children: [
      { path: 'login', element: <DealerLoginPage /> },
      { path: 'admin/login', element: <AdminLoginPage /> },
      { path: 'login/phone', element: <DealerLoginPhonePage /> },
      { path: 'register', element: <DealerRegisterPage /> },
      { path: 'forgot-password', element: <DealerForgotPasswordPage /> },
      { path: 'forgot-password-verify', element: <DealerVerifyRecoverPasswordPage /> },
      { path: 'new-password', element: <DealerNewPasswordPage /> },
      { path: 'login/verify', element: <DealerLoginVerifyPage /> },
    ]
  }
];
