import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//

import { Suspense, createElement, lazy, useCallback, useEffect, useState } from 'react';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard';
import Iconify from 'src/components/iconify';


import { useAuthContext } from 'src/auth/hooks';
import { useLocales } from 'src/locales';
import { mainRoutes, HomePage, LandingPage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';

const TableConfigurationListView = lazy(() => import('src/sections/masters/settings/table-configuration/table-configuration-view'));
const CitiesListPage = lazy(() => import('src/pages/dashboard/masters/geography-settings/cities'));
const CountryListPage = lazy(() => import('src/pages/dashboard/masters/geography-settings/countries'));
const DistrictsListPage = lazy(() => import('src/pages/dashboard/masters/geography-settings/districts'));
const MandalListPage = lazy(() => import('src/pages/dashboard/masters/geography-settings/mandal'));
const PincodeListPage = lazy(() => import('src/pages/dashboard/masters/geography-settings/pincode'));
const StatesListPage = lazy(() => import('src/pages/dashboard/masters/geography-settings/states'));
const CitiesListView = lazy(() => import('src/sections/masters/geography-settings/cities/cities-list-view'));
const CountriesListView = lazy(() => import('src/sections/masters/geography-settings/countries/countries-list-view'));
const DistrictsListView = lazy(() => import('src/sections/masters/geography-settings/districts/districts-list-view'));
const MandalsListView = lazy(() => import('src/sections/masters/geography-settings/mandal/mandal-list-view'));
const PincodesListView = lazy(() => import('src/sections/masters/geography-settings/pincode/pincode-list-view'));
const StatesListView = lazy(() => import('src/sections/masters/geography-settings/states/states-list-view'));
const DynamicFormListView = lazy(() => import('src/sections/masters/settings/dynamic-form/dynamic-form-view'));
const FormSetupListView = lazy(() => import('src/sections/masters/settings/form-setup/form-setup-view'));
const FormBlockSetupListView = lazy(() => import('src/sections/masters/settings/form-block-setup/form-block-setup-view'));
const TableCreationListView = lazy(() => import('src/sections/masters/settings/table-creation/table-creation-view'));
const PageFormSetupListView = lazy(() => import('src/sections/masters/settings/page-form-setup/page-form-setup-view'));
const DynamicFormNewPage = lazy(() => import('src/sections/masters/settings/dynamic-form/dynamic-form-new-page'));
const RolesDynamicFormListView = lazy(() => import('src/sections/masters/settings/roles/roles.view'));
// ----------------------------------------------------------------------
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

export const Components: any = {
  DASHBOARD: <IndexPage />,
  COUNTRYLISTPAGE: <CountryListPage />,
  COUNTRIESLISTVIEW: <CountriesListView />,
  STATESLISTPAGE: <StatesListPage />,
  STATESLISTVIEW: <StatesListView />,
  DISTRICTSLISTPAGE: <DistrictsListPage />,
  DISTICTSLISTVIEW: <DistrictsListView />,
  CITIESLISTPAGE: <CitiesListPage />,
  CITIESLISTVIEW: <CitiesListView />,
  MANDALLISTPAGE: <MandalListPage />,
  MANDALSLISTVIEW: <MandalsListView />,
  PINCODESLISTPAGE: <PincodeListPage />,
  PINCODESLISTVIEW: <PincodesListView />,
  DYNAMICFORMLISTVIEW: <DynamicFormListView />,
  TABLECONFIGURATIONLISTVIEW: <TableConfigurationListView />,
  FORMSETUP: <FormSetupListView />,
  FORMBLOCKSETUP: <FormBlockSetupListView />,
  PAGEFORMSETUPLISTVIEW: <PageFormSetupListView />,
  DYNAMICPAGE: <DynamicFormListView />,
  DYNAMICNEWPAGE: <DynamicFormNewPage />,
  ROLESPAGE: <DynamicFormNewPage />,
  TABLECREATIONLISTVIEW: <TableCreationListView />,
  ROLESDYNAMICFORMLISTVIEW: <RolesDynamicFormListView />,
  REGISTERPAGE: <DynamicFormListView />,
};

export const CreateDynamicComponent = (elementName: any) => {
  // component does exist
  const { componentName, id, title } = elementName
  if (typeof Components[componentName] !== "undefined") {
    return Components[componentName];
  }
  // component doesn't exist yet
  return createElement(
    () => <div>The component <b>{title}</b> has not been created yet.</div>,
    { key: id }
  );
}



export default function Router() {
  const { t } = useLocales();
  const { user } = useAuthContext();
  const [menuItem, setMenuItem] = useState([] as any[]);
  const [authRouteData, setAuthRouteData] = useState([] as any[]);
  const [addEditMenuItem, setAddEditMenuItem] = useState([] as any[]);
  const dashboard = {}

  const addMenuChildren = useCallback((dt: any[], dtchild: any[]) => {
    dtchild.forEach((dr: any) => {
      const subchild2 = dt.filter(p => p.parentId === dr.id)
      const subchild: any[] = [];
      subchild2.forEach(x => {
        const item: any = {} as any
        item.title = t(x.title);
        item.path = `${x.path}${x.path.indexOf(':id') === -1 ? "" : x.path.replace(":id", x.id)}`;
        item.id = x.id;
        item.addEditUrl = x.addEditUrl;
        if(item.addEditUrl) {
          addEditMenuItem.push({ element: <DynamicFormNewPage />, path:item.addEditUrl}) 
        }
        if (x.componentName !== '') {
          item.element = CreateDynamicComponent(x);
        }
        if (x.icon !== '') {
          if (x.icon !== '') {
            item.icon = x.icon;
          }
        }
        if (item.addEditUrl) {
          const routePath = {
            path: item.path,
            children: [
              { element: item.element, index: true },
              { path: ':viewId', element: <DynamicFormNewPage /> },
            ],
          }
          subchild.push({ ...routePath });
        } else {
          subchild.push({ ...item });
        }
      })
      dr.title = t(dr.title);
      if (subchild != null && subchild.length > 0) {
        dr.children = subchild;
        const dtsubchild: any[] = subchild;
        addMenuChildren(dt, dtsubchild);
      }
    })
    return dtchild;
  }, [t])

  const getMenuStructure = useCallback((dt: any[]) => {
    let parent: any[] = [];
    const dtparent: any[] = [];
    if (dt != null && dt.length > 0) {
      parent = dt.filter(p => p.parentId === 0)

      if (parent != null && parent.length > 0) {
        parent.forEach(x => {
          const item: any = {} as any
          item.title = t(x.title);
          item.path = x.path.indexOf(':id') === -1 ? "" : x.path.replace(":id", x.id);
          item.id = x.id;
          item.addEditUrl = x.addEditUrl;
          if(item.addEditUrl) {
            addEditMenuItem.push({ element: <DynamicFormNewPage />, path:item.addEditUrl}) 
          }
          if (x.componentName !== '') {
            item.element = CreateDynamicComponent(x);
          }
          if (x.icon !== '' && x.icon !== null) {
            if (x.icon !== '') {
              item.icon = x.icon;
            }
          }
          if (item.addEditUrl) {
            const routePath = {
              path: item.path,
              children: [
                { element: item.element, index: true },
                { path: ':viewId', element: <DynamicFormNewPage /> },
              ],
            }
            dtparent.push({ ...routePath });
          } else {
            dtparent.push({ ...item });
          }
        })

        dtparent.forEach((dr: any) => {
          const child1 = dt.filter(p => p.parentId === dr.id);
          const child: any[] = [];
          child1.forEach(x => {
            const item: any = {} as any
            item.title = t(x.title);
            item.path = x.path;
            item.id = x.id;
            item.addEditUrl = x.addEditUrl;
            if(item.addEditUrl) {
              addEditMenuItem.push({ element: <DynamicFormNewPage />, path:item.addEditUrl}) 
            }
            if (x.componentName !== '') {
              item.element = CreateDynamicComponent(x);
            }
            if (x.icon !== '') {
              item.icon = x.icon;
            }
            if (item.addEditUrl) {
              const routePath = {
                path: item.path,
                children: [
                  { element: item.element, index: true },
                  { path: ':viewId', element: <DynamicFormNewPage /> },
                ],
              }
              child.push({ ...routePath });
            } else {
              child.push({ ...item });
            }
           
          })
          if (child != null && child.length > 0) {
            dr.children = child;
            const dtchild: any[] = child;
            addMenuChildren(dt, dtchild);
          }
        })
      }
    }
    return dtparent;
  }, [addMenuChildren, t])


  useEffect(() => {
    const menutItems: any[] = user?.menuItems || [];
    const menuItemsLevel0: any[] = menutItems.filter(x => x.level === 0);
    const menuItemsLevel1: any[] = menutItems.filter(x => x.level === 1);
    const authData: any[] = menutItems.filter(x => x.level === -1);
    const dataLevel0: any[] = getMenuStructure(menuItemsLevel0)
    const data: any[] = getMenuStructure(menuItemsLevel1)
    const authDataRoutes: any[] = getMenuStructure(authData)

    setMenuItem([{
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ), children: [{ element: <IndexPage />, index: true }, ...dataLevel0, ...data]
    },
    {
      path: 'auth',
      element: <DynamicFormNewPage/>, children: [{ element: <IndexPage />, index: true }, ...authData,]
    },]);
    console.log(data);
  }, [user, getMenuStructure])


  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [{ element: <HomePage />, index: true }],
    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...menuItem,
    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
  ]);
}
